import * as React from 'react'

import { cn } from '@biogroup/utils/cn'

import { ErrorList, type ListOfErrors } from './error-list.tsx'
import { Input, type InputProps } from './input.tsx'
import { Label } from './label.tsx'

interface Props {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  inputProps: InputProps
  errors?: ListOfErrors
  description?: string
  className?: string
}

export const Field = React.forwardRef<HTMLInputElement, Props>(
  ({ labelProps, inputProps, errors, description, className }, ref) => {
    const fallbackId = React.useId()
    const id = inputProps.id ?? fallbackId
    const errorId = errors?.length ? `${id}-error` : undefined
    const descriptionId = `${id}-description`

    return (
      <div className={cn('flex flex-col gap-y-2', className)}>
        <Label htmlFor={id} {...labelProps} />
        <div className="relative">
          <Input
            id={id}
            ref={ref}
            aria-invalid={errorId ? true : undefined}
            aria-describedby={
              errors ? errorId : description ? descriptionId : undefined
            }
            {...inputProps}
          />
        </div>
        {errorId ? (
          <ErrorList id={errorId} errors={errors} />
        ) : description ? (
          <div id={descriptionId} className="text-xs text-muted-foreground">
            {description}
          </div>
        ) : null}
      </div>
    )
  },
)
Field.displayName = 'Field'
